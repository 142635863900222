import { PageProps } from "gatsby";
import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { Redirect } from '@reach/router';

import * as utils from "~/utils";
import { SEO } from "~/components/layout/Seo";
import { BoxAnimation } from "~/components/generating/BoxAnimation";
// import { ScrollIntoView } from "~/components/common/ScrollIntoView";
import { words as wordsState } from "~/recoil";

interface CustomizeProps extends PageProps {}
const Customize: React.FC<CustomizeProps> = () => {
  const [words,] = useRecoilState(wordsState);

  useEffect(() => {
    utils.scrollToMainContent();
  }, []);

  return (
    words.length ? <>
      <SEO />
      {/* <ScrollIntoView /> */}
      <BoxAnimation />
    </> : <Redirect to={utils.getPath('home') } noThrow />
  )
}
export default Customize;