import React from 'react';
import styled from '@emotion/styled';
import { TWord, Stylable } from '~/content/types';
import { dimensions, fonts } from '~/styles';


const StyledWord = styled.span`
  text-align: center;
  color: black;
  width: 74px;
  line-height: 40px;
  display: inline-block;
  font-family: ${fonts.pills};
  font-size: ${dimensions.fontSize.small}px;
  font-weight: bold;

  > span {
    position: relative;
    z-index: 2;
    display: block;
    text-align: center;
    left: -2px;
  }

  > img {
    position: absolute;
    top: 0;
    left: -5px;
    width: 100%;
    pointer-events: none;
    user-select: none;
    z-index: -1;
  }
`

export interface WordProps extends Stylable {
  word: TWord;  
} 
const Word: React.FC<WordProps> = ({ word, className }) => {
  return ( 
    <StyledWord className={className}>
      <img src={`/images/pill-bg.png`} alt="" />
      <span>{word.text}</span>

      {/* <img src={`/images/words/${word.text}.png`} alt="" /> */}
    </StyledWord>
  );
}
 
export { Word };