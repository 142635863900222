/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import Helmet from "react-helmet";

import * as utils from "~/utils";
import { apiSiteMetaQuery } from "~/graphql";
import { getAppWebRoot } from "~/utils/api";


type MetaProps = JSX.IntrinsicElements["meta"]

interface SEOProps {
  description?: string
  lang?: string
  meta?: MetaProps[]
  title?: string
}

export default function SEO({ description, lang = `en`, meta = [], title }: SEOProps) {
  const siteMetadata = apiSiteMetaQuery();

  const metaDescription = description || utils.t('Create your gentle message and send it to the world!') || siteMetadata.description!
  const metaTitle = title ? `${title} | ${siteMetadata.title}` : utils.t('Tic Tac® - Gentle messages') || siteMetadata.title!;

  const constantMeta: MetaProps[] = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: metaTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:image`,
      content: `${getAppWebRoot()}/images/cover.jpg`
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: siteMetadata.author!,
    },
    {
      name: `twitter:title`,
      content: metaTitle,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ]

  return (
    <Helmet
      meta={constantMeta.concat(meta)}
    >
      <html lang={lang} dir="rtl" />
      <title>{metaTitle}</title>
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link href="https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;700&display=swap" rel="stylesheet" />
      <script>
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-N2GCP4L');
        `}
      </script>
    </Helmet>
  )
}

export { SEO }