import React, { useEffect, useState, useMemo, useContext } from 'react';
import { navigate } from "gatsby";
import styled from "@emotion/styled"; 
import { motion } from "framer-motion";
import { useRecoilState } from 'recoil';

import * as utils from "~/utils";
import { fluidRange, respondFrom, respondTo, breakpoints, css } from '~/styles';
import { words as wordsState } from "~/recoil";
import { Word } from '~/components/common/Word';
import localeContext from '~/localeContext';

/** animations */
const variantsBox = {
  rest: {
    rotate: 0,
    transition: {
      type: 'tween',
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
  shake: {
    rotate: [-5, 3, -2, 5, -1, 6, 1, 4, -2, 1],
    transition: {
      flip: Infinity,
      duration: 0.5
    }
  },
  fall: {
    rotate: 45,
    originX: '70%',
    originY: '90%',
    transition: {
      type: 'spring',
      duration: 0.25,
      damping: 12
    }
  },
  fallMobile: {
    rotate: 0,
    y: [20, -50, 0],
    transition: {
      type: 'spring',
      duration: 0.3
      // damping: 100,
      // stiffness: 50
    }
  }
}

const variantsPills = {
  pour: {
    transition: {
      staggerChildren: 0.2
    }
  }
}
const variantsPill = {
  initial: {
    opacity: 0
  },
  pour: {
    opacity: 1
  }
}

/** styles */
const localMobileBreakpoint = breakpoints.md;
const localDesktopBreakpoint = breakpoints.lg;

const StyledContainer = styled.div``

const StyledContainerInner = styled.div`
  > * {
    position: relative;
  }

  ${respondFrom(
    localMobileBreakpoint,
    css`
      display: flex;
      flex-flow: row;

      > * {
        flex-basis: 50%;
      }
    `
  )}
`

const StyledBoxCont = styled.div`
`
const StyledWordsCont = styled.div`
  ${respondTo(
    localMobileBreakpoint,
    css`
      margin-top: 40px;
    `
  )}
`

const StyledBox = styled(motion.div)`
  ${fluidRange('paddingBottom', '30px', '50px')}

  ${respondTo(
    localMobileBreakpoint,
    css`
      text-align: center;
    `
  )};

  ${respondFrom(
    localMobileBreakpoint,
    css`
      transform: translateX(-25%);
    `
  )};

  ${respondFrom(
    localDesktopBreakpoint,
    css`
      transform: translateX(-35%);
    `
  )};

  img {
    width: 352px;
    /* height: 419px; */
  }
`

const StyledWords = styled(motion.div)<StylesProps>`

  text-align: ${ props => props.wordsCount > 3 ? 'center' : 'left' };

  ${respondTo(
    localMobileBreakpoint,
    css`
      text-align: center;
    `
  )}
  ${respondFrom(
    localMobileBreakpoint,
    css`
      position: absolute;
      left: 5%;
      right: 0;
      top: 20%;
    `
  )}
`

const StyledWordCont = styled(motion.span)`
  display: inline-block;
  position: relative;
  margin: 0 7px 12px;
`

interface StylesProps {
  wordsCount: number
}
type AnimationType = 'shake' | 'fall' | 'pour' | 'fallMobile';
export const BoxAnimation: React.FC<{}> = () => {
  const [words,] = useRecoilState(wordsState);
  const [animationStep, setAnimationStep] = useState<AnimationType>('shake');
  const { packshotImageUrl } = useContext(localeContext);

  const pathCustomize = utils.getPath('customize');
  useEffect(() => {
    let timeout;

    timeout = setTimeout(() => {
      setAnimationStep(mobileVersion ? 'fallMobile' : 'fall');
    }, 1200)

    timeout = setTimeout(() => {
      setAnimationStep('pour');
    }, 1500)

    timeout = setTimeout(() => {
      navigate( pathCustomize );
    }, 4000)  

    return function cleanup() {
      !!timeout ? clearTimeout( timeout ) : null;
    }
  }, []);

  const mobileVersion = useMemo(() => {
    return !utils.isSSR && window.innerWidth < localMobileBreakpoint;
  }, [])

  return (
    <StyledContainer>
      <h1>{ utils.t('Creating your message...') }</h1>

      <StyledContainerInner>
        <StyledWordsCont>
          <StyledWords
            variants={variantsPills}
            animate={animationStep}

            wordsCount={words.length}
          >
            {
              words.map( (word,i) => (
                <StyledWordCont
                  key={`${word.text}${word.key}`}
                  variants={{ ...variantsPill,
                    shake: {
                      ...variantsPill.initial,
                      rotate: - Math.random() * 50.0,
                      y: mobileVersion ? `20%` : `-50%`,
                    },
                    pour: {
                      ...variantsPill.pour,
                      // pos/rotate randomized a little
                      rotate: Math.random() * 30.0 - 15.0,
                      y: Math.random() * 20.0 - 10.0,
                      x: Math.random() * 10.0 - 5.0,
                    }
                  }}
                  initial={{
                    opacity: 0,
                    x: mobileVersion ? 0 : `${-100*i}%`
                  }}
                  animate={animationStep}
                  transition={{
                    type: 'spring',
                    stiffness: 400,
                    damping: 50
                  }}
                >
                  <Word word={word}></Word>
                </StyledWordCont>
              ))
            }
          </StyledWords>
        </StyledWordsCont>
        <StyledBoxCont>
          <StyledBox
          >
            <motion.img src={packshotImageUrl || `/images/packshot.png`} alt="" 
              variants={variantsBox} 
              initial="rest" 
              animate={animationStep}
            />
          </StyledBox>
        </StyledBoxCont>
      </StyledContainerInner>

    </StyledContainer>
  )
};